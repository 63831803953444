@import '../Styles/Color.scss';
@import '../Styles/FontSize.scss';
@import '../Styles/hsize.scss';

.s-blue {
    background-image: url("../images/frame-1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    // padding: 120px 116px;
}

.mt-120 {
    margin-top: 120px;
}

.f-man {
    max-width: 524px;
    position: relative;
    top: 47px;
    min-height: 452px;

}

.f-img {
    padding-top: 87px;
}

.card {
    max-width: 310px;
    min-height: 454px;
    border-radius: 20px;
    border: 1px solid #B5B5C3;
    padding: 26px;
    margin-bottom: 59px;
    position: relative;
}

.card-image {
    height: 190px;
}

.reviews {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #7F7E97;
}

.card-btn {
    width: auto;
    height: 27px;
    background-color: #EFEFF2;
    border-radius: 20px;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    color: #161439;

}

.en-now {
    width: 133px;
    height: 40px;
    background-color: #5751E1;
    border-radius: 20px;
    border: none;
    margin-right: 35px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #FFFFFF;
    position: absolute;
    left: 24px;
    bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.card-price {
    font-size: $font-medium;
    font-weight: bold;
    line-height: 20px;
    color: $primary-color;
    font-family: "Inter", sans-serif;
    position: absolute;
    right: 24px;
    bottom: 39px;
}

.btn-yellow.start-btn {
    max-width: 202px;
    min-height: 49px;
    border-radius: 50px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 17.92px;
    font-weight: bold;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 30px;
    padding-right: 18px;
    color: #161439;
    font-family: "Inter", sans-serif;

}

.css-fpmqmn-MuiTabs-root {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: #7F7E97;
}

.s-sky-blue {
    background-image: linear-gradient(#282568, #282568);
    background-size: cover;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-repeat: no-repeat;
    min-height: 264.22px;
    border-radius: 20px;
    border: none;
}

.paddings {
    padding: 60px 75px;
}

.slider-div {
    max-width: 636px;
    min-height: 170px;
    background-color: #ffffff;
    border: 15px solid #5751E1;
    padding: 39px 40px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 15px;
}

.slider-div::after {
    content: "";
    position: absolute;
    bottom: -25px;
    width: 20px;
    left: 5%;
    height: 20px;
    transform: rotate(45deg);
    background-color: #5751E1;
}

.lgt-blue {
    padding-top: 58px;
    min-height: 806px;
}

.mt-24 {
    margin-top: 24px;
}

.ml-26 {
    margin-left: 26px;
}

.pt-11 {
    padding-top: 11px;
}

.pl-20 {
    padding-left: 20px;
}

.pb-11 {
    padding-bottom: 11px;
}

.h3-black {
    font-size: $font-medium;
    font-weight: 600;
    line-height: 25.2px;
    color: #161439;
    font-family: "Inter", sans-serif;
}

.cd-p {
    font-size: 15px;
    font-weight: $font-weight-medium;
    line-height: 15px;
    color: #161439;
    margin-top: 13px;
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
}

.h1-white {
    font-size: $font-large;
    font-weight: $font-weight-regular;
    line-height: 60px;
    color: #ffffff;
    font-family: "Inter", sans-serif;
}

.p-white {
    font-size: $font-medium;
    font-weight: $font-weight-medium;
    line-height: 28.8px;
    color: #ffffff;
    text-align: justify;
    font-family: "Inter", sans-serif;
}

.h1-black {

    font-size: 36px;
    font-weight: 600;
    line-height: 47.88px;
    letter-spacing: -0.75px;
    color: #161439;
    margin-top: 22px;
    margin-bottom: 8px;
    font-family: "Inter", sans-serif;
}

.p-6D {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #6D6C80;
    font-family: "Inter", sans-serif;
}

.man-sucess {
    background-image: url("../images/frame-1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 410px;
    padding-top: 95px;
}

.s-man {
    max-width: 330.33px;
    min-height: 361.12px;
}

.btn-white {
    max-width: 245px;
    min-height: 48px;
    background-color: #FFFFFF;
    border-radius: 50px;
    font-style: bold;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #000000;
    padding: 10px;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
}

.img-round {
    max-width: 112px;
    min-height: 75px;
}

.colon {
    max-width: 112px;
    min-height: 75px;
    margin-top: 60px;
    margin-bottom: 16px;
}

.slider-p {
    font-size: 17px;
    font-weight: 400;
    line-height: 30.6px;
    color: #6A6C72;
    font-family: "Inter", sans-serif;
}

.horn {
    max-width: 213px;
    min-height: 56px;
    position: relative;
    bottom: 20px;
    left: 20px;
}

.flex-1 {
    flex: 1;
}

.gap-10 {
    gap: 10px;
}

.gap {
    gap: 3px;
}

.mr-18 {
    margin-right: 18px;
}

.min-height {
    min-height: 700px;
}

.mt-35 {
    margin-top: 35px;
}

.tw-colon {
    width: 30px;
    height: 30px;
    margin-left: 500px;
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.custom-next-arrow {
    background: #5751E1 !important;
    border: 1px solid black !important;
    position: relative;
    right: 0px !important;
}

.custom-previous-arrow {
    background: #5751E1 !important;
    border: 1px solid black !important;
    position: relative;
    left: 0px !important;
    z-index: 1 !important;
}

.custom-previous-arrow::before {
    content: '' !important;
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    border-left: 5px solid white;
    border-bottom: 5px solid white;
    border-radius: 5px 0px;
}

.custom-next-arrow::before {
    content: '' !important;
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    border-top: 5px solid white;
    border-right: 5px solid white;
    border-radius: 5px 0px;
}

.slider-button {
    background-color: #5751E1;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    box-shadow: 2px 2px 2px #000000;
    border: 1px solid #000000;
    bottom: 39px;
    right: 6px;
    position: relative;
    color: #FFFFFF;
}

.btn-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 35%;
}

// @media screen and (max-width: 325px) {
//     .btn-white {
//         font-size: 16px;
//     }

//     .btn-yellow.start-btn {
//         margin-left: 30px;
//     }


// }

// @media screen and (max-width: 375px) {
//     .f-man {
//         display: none;
//     }

//     .s-sky-blue {
//         flex-direction: column;
//         height: 383px;
//     }

//     .btn-yellow.start-btn {
//         margin-left: 60px;
//     }

//     .btn-white {
//         font-size: 16px;
//     }

// }

// @media screen and (max-width: 425px) {
//     .btn-white {
//         right: 20px;
//     }

//     .s-sky-blue {
//         flex-direction: column;
//         height: 383px;
//     }

//     .btn-yellow.start-btn {
//         margin-left: 60px;
//     }

// }

// @media screen and (max-width: 767.98px) {
//     .icons-div {
//         flex-direction: column;
//         gap: 30px;

//     }
// }
@media screen and (max-width: 322px) {
    .btn-white {
        font-size: 14px;
        margin-left: 60px !important;
    }

    .btn-yellow.start-btn {
        margin-left: 30px;

    }

    .p-white {
        font-size: 14px;
    }

    .h1-white {
        font-size: 24px;
    }

    .card {
        max-width: 294px !important;
    }

    .MuiButtonBase-root {
        padding: 0px !important;
        margin-left: 20px !important;

    }
}

@media screen and (max-width: 480px) {
    .f-man {
        display: none;
    }

    .s-sky-blue {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .btn-yellow.start-btn {
        margin-left: 95px;
    }

    .btn-white {
        right: 20px;
        font-size: 16px;
    }

    h1 {
        font-size: 16px;
    }

    .slider-button {
        right: 0px !important;
    }
}

@media screen and (max-width: 767.98px) {
    .icons-div {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;

    }

    .slider-button {
        right: 35px;
    }

    .card {
        max-width: 232px;
    }

    .h3-black {
        font-size: 14px;
    }

    .en-now {
        width: 123px;
        margin-right: 21px;
        font-size: 13px;
    }

    .card-price {
        font-size: 14px;
    }
}

@media screen and (max-width: 1024px) {
    .btn-white {
        font-size: 16px;
    }

    .p-white {
        font-size: 16px;
    }

    .h1-white {
        font-size: 32px;
    }

    .slider-div {
        max-width: 453px;
    }

}