.cd-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping, so all items stay on one line */
  justify-content: space-between;
}
.cd {
  margin-right: 15px;
  max-width: 230px;
  min-height: 236px;
}
.slick-track {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.similar-course {
  color: aqua;
}
.course-list-container {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}
/* For custom scrollbar styles */
.course-list-container::-webkit-scrollbar {
  width: 8px;
}

.course-list-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.course-list-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.img-fluid.flex-img {
  max-width: 100px;
  min-height: 60px;
}
/* .img-fluid.adv-img {
  max-width: 848px;
  min-height: 471px;
  border-radius: 10px 0px 0px 0px;
} */
.detail-h1 {
  font-family: "Inter", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: -0.4000000059604645px;
  color: #161439;
}
.course-padding {
  padding-top: 60px;
}
.crs-p {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #4d5e6f;
}
.course-h2 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -1px;
  color: #161439;
}
.crs-list {
  list-style-type: none;
}

.crs-list li {
  position: relative;
  padding-left: 20px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #4d5e6f;
}
.crs-list li::before {
  content: "•";
  color: #4d5e6f;
  position: absolute;
  left: 0;
}
.crs-h4 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #161439;
}
.mt-20 {
  margin-top: 20px;
}
.b-free {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  color: #161439;
}
.gap-3 {
  gap: 3px;
}
.gap-5 {
  gap: 5px;
}
.mr-20 {
  margin-right: 20px;
}
strike {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  color: #4d5e6f;
  text-decoration-line: line-through;
}
.b-price {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  color: #161439;
  margin-left: 8px;
  margin-right: 10px;
}
.crs-demo {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #808c98;
}
.crs-env {
  min-width: 350px;
  min-height: 60px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #5751e1;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.8px;
  border: none;
}
.crs-h3 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  color: #161439;
  margin-top: 40px;
}
/* .crs-hr {
  color: #dbe0e9;
  
} */
.crs-sm {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  color: #4d5e6f;
}
.crs-big {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.15px;
  color: #161439;
}
.f-15 {
  font-size: 15px;
}
.lh-18 {
  line-height: 18px;
}
.gap-20 {
  gap: 20px;
}
.flex-img1 {
  max-width: 56px;
  min-height: 42px;
}
.mr {
  margin-right: 50px;
  border-right: 1px solid #7f7e97;
  padding-right: 50px;
}
.m-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mr-16 {
  margin-right: 16px;
}
.course-h3 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  color: #161439;
}
.slick-dots li button:before {
  font-size: 18px; /* Increase the size */
  color: #7f7e97; /* Dot color */
  padding: 10px; /* Add padding to make it larger */
}

.slick-dots li.slick-active button:before {
  color: #5751e1; /* Active dot color */
  border-radius: 50%; /* Make dots circular */
}

@media screen and (max-width: 768px) {
  .g-30 {
    gap: 30px;
  }
  .detail-h1 {
    font-size: 34px;
  }
  .img-fluid.flex-img1 {
    max-width: 68px;
  }
}
@media screen and (max-width: 1025px) {
  .g-30 {
    gap: 30px;
  }
  .crs-env {
    min-width: 308px;
  }
}
@media screen and (max-width: 426px) {
  .icon-d {
    margin-right: 5px;
  }
  .span {
    font-size: 11px;
    line-height: 26px;
    margin-right: unset;
  }
  .res-col {
    flex-direction: column;
  }
  .crs-env {
    margin-left: 50px;
  }
  .detail-h1 {
    font-size: 26px;
  }
  .mr {
    padding-right: 12px;
  }
}
@media screen and (max-width: 322px) {
  .crs-env {
    margin-left: 0px;
  }
}
