section.cart-breadcrum {
  background-image: url("../images/breadcrum.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 480px;
  color: #ffffff;
  font-size: 21px;
  line-height: 40px;
  font-family: "Inter", sans-serif;
}
.cart-container {
  max-width: 1300px;
  margin: 0 auto;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #7f7e97;
  margin-bottom: 32px;
  margin-top: 58px;
}
.sc-gtLWhw.caNzNe {
  /* border-bottom: 0.3px solid #b5aaaa; */
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #161439;
}
.sc-dstKZu.kFjqSG {
  background-color: #ffffff !important;
}
.sc-dstKZu.kWafnY {
  border-bottom-color: 0.5px solid #b5aaaa;
  background-color: #ffffff;
}
.sc-egkSDF.kdFliv.rdt_TableHeadRow {
  background-color: unset !important;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #161439;
}
/* .dLHSn.WRRsr {
  flex-grow: 4;
} */

.product-cell {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0;
}

.product-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.product-info {
  display: flex;
  flex-direction: column;
}

.product-title {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #161439;
}

.product-author {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 28px;
  color: #161439;
}

.cart-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.update-cart-button {
  background-color: #ffffff;
  color: #5751e1;
  padding: 13px 37px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.92px;
  border: 1px solid #5751e1;
}

.update-cart-button:hover {
  background-color: #4338ca;
}
.cart-data-table {
  width: 1320px;
  height: 317px;
  border-radius: 20px;
  border: 1px solid #7f7e97;
  margin-top: 45px;
  margin-bottom: 100px;
  padding: 30px 40px;
}
.proced-btn {
  width: 222px;
  height: 49px;
  background-color: #5751e1;
  color: #ffffff;
  border-radius: 20px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.92px;
  padding: 13px 24px;
  border: none;
}
.cart-hr {
  width: 100%;
}
.breadcrumb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.breadcrumb-item a,
.breadcrumb-item.active {
  color: white;
  text-decoration: none;
}

.breadcrumb-item {
  width: unset;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  color: #ffffff; /* Optional: Change the color of the separator */
}
@media screen and (max-width: 767.98px) {
  section.cart-breadcrum {
    font-size: 14px;
    line-height: 28px;
  }
  section.cart-breadcrum h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48.41px;
  }
  section.cart-breadcrum .breadcrumb {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}
