@import '../Styles/Color.scss';
@import '../Styles/FontSize.scss';

h1 {
  font-size: $font-large;
  font-weight: 500;
  line-height: 60px;
  color: #ffffff;
  font-family: "Inter", sans-serif;
}

h3 {
  font-size: $font-md;
  font-weight: 500;
  line-height: 19.2px;
  color: #ffffff;
  font-family: "Inter", sans-serif;
}