.modal-30w {
  margin-right: 60px;
  width: 465px;
  height: 744px;
  padding: 0px !important;
}
.myCart-btn {
  width: 465px;
  height: 64px;
  padding: 0px 137px 0px 137px;
  background-color: #5751e1;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
  border: none;
  padding: 23px 137px;
}
.myCart-btn1 {
  width: 465px;
  height: 50px;
  background-color: #e8e8e8;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #161439;
  border: none;
  text-align: start;
}
.bg-color-lgt {
  width: 465px;
  height: 200px;
  background-color: #dae3ff;
  padding: 16px;
  margin-top: 40px;
}
.cart-bill {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #101a39;
}
.cart-total {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #101a39;
  margin-top: 16px;
}
.mycart-price {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: #101a39;
}
.cart-total1 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #101a39;
  margin-top: 16px;
}
.border-dotted {
  border-top: 2px dashed #b5b5b5;
  width: 100%;
  margin-bottom: 16px;
}
.mycart-img {
  width: 89px;
  height: 88px;
  position: absolute;
  top: 8px;
  left: 15px;
  bottom: 10px;
}
.bg-color-light {
  background-color: #f5f7fd;
  border-bottom: 0.5px solid #b5aaaa;
  position: relative;
}
.cross-cart {
  position: absolute;
  right: 20px;
  top: 38px;
}
.gap-24 {
  gap: 24px;
}
.mycart-text {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #161439;
  /* white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100px;
  text-overflow: ellipsis;
  display: block; */
  margin-top: 22px;
  margin-bottom: 1px;
}

.by {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  color: #6d6c80;
}
.cart-p {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  color: #161439;
}
.mb-20 {
  margin-bottom: 20px;
}
.modal-body {
  padding: 0px !important;
}
