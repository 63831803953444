.modal-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 950px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}
.main-container .modal-body {
  padding: 0px;
}
.login-img {
  max-width: 250px;
  min-height: 62px;
}
.login-img1 {
  max-width: 311.75px;
  min-height: 206.64px;
}
.bg-color-blue {
  max-width: 663px;
  min-height: 683px;
  background-color: #5751e1;
  padding: 100px 48px 50px 48px;
}
.login-input {
  width: 450px;
  height: 72px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  color: #101a39;
  margin-top: 110px;
}
.login-continue {
  width: 450px;
  height: 72px;
  background-color: #f2c413;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  color: #ffffff;
  border-radius: 10px;
  margin-top: 40px;
}

.login-p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  color: #ffffff;
  margin-top: 108px;
}
.bg-color-white {
  background-color: #ffffff;
  padding: 100px 48px 50px 48px;
}
.login-h1 {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  color: #101a39;
}
.login-p1 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  color: #101a39;
  margin-top: 16px;
  margin-bottom: 211px;
}
.six-div {
  width: 53px;
  height: 72px;
  background-color: #ffffff;
  border-radius: 31px;
  margin-top: 70px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.otp-h1 {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  color: #ffffff;
  margin-bottom: 30px;
}
.otp-num {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  color: #ffffff;
}
.otp-time {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: center;
  color: #ffffff;
}
.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
