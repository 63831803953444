@import '../Styles/Color.scss';
@import '../Styles/FontSize.scss';

.navbar-first {
  background-color: #161439;
  ;
  min-height: 48px;
  font-family: "Inter", sans-serif;
}

.navbar-icon {
  height: 12px;
  padding: 0px 13px;
  border-right: 1px solid #ffffff;

  &:last-child {
    border-right: none;
  }
}

.navbar-icon1 {
  height: 12px;
  margin-right: 4px;
}

.nav-item {
  width: unset;
  height: unset;
  font-family: "Inter", sans-serif;
}

.navbar-options {
  flex: 1;
  font-family: "Inter", sans-serif;
}

.nav-link.home-text {
  font-family: "Inter", sans-serif;
  font-size: 9px;
  font-weight: 400;
  line-height: 13.01px;
  letter-spacing: 0.04em;
  text-align: left;
  padding-top: 0px;
}

.icon-d {
  margin-right: 10px;
  color: #FFFFFF;
}

.btn-yellow {
  background-color: $secondary-color;
}

.l-btn {
  width: 97px;
  height: 44px;
  border-radius: 20px;
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: #161439;
}

.main-Logo {
  max-width: 153px;
  min-height: 38px;
  margin-right: 20px;
}

select {
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

.span {
  color: #E6EAEF;
  font-family: "Inter", sans-serif;
  font-size: $font-md;
  font-weight: 400;
  line-height: 24px;
  margin-right: 7px;
  padding: 0px 10px;

}

.navbar-second .leela-logo {
  color: #117d78;
  width: 146px;
  height: 62.13px;
}

.navbar-second ul {
  min-height: 27px;
  font-family: "Inter", sans-serif;
  font-size: $font-medium;
  font-weight: 500;
  line-height: 27px;
  color: #222222;
  border: none;
}

.gap-35 {
  gap: 35px;
}


.navbar-expand-lg {
  position: sticky;
  top: 0;
  background: $white-color;
  z-index: 200;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}

.navbar-light {
  padding: 23px 60px;
}

.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
}

.navbar-second .search-icons {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  margin-left: auto;
}

.n-icon {
  width: 46px;
  height: 46px;
}

.search-container {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 50px;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 444px;
  margin: 0 auto;
}

.dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  border-right: 1px solid #e0e0e0;
  position: relative;
}

.dropdown span {
  margin: 0 10px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24.5px;
}

.icon {
  width: 20px;
  height: 20px;
  color: #666;
}

input {
  flex-grow: 1;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  outline: none;
  font-family: "Inter", sans-serif;
}

.mx-2 {
  margin-left: 0.5rem;
  /* Adjust as needed */
  margin-right: 0.5rem;
  /* Adjust as needed */
}

.gap-34 {
  gap: 34px;
}

.gap-15 {
  gap: 15px;
}

.btn-lgt {
  width: 175px;
  height: 31px;
  background-color: #EFEEFE;
  border-radius: 20px;
  border: none;
  color: #5751E1;
  font-family: "Inter", sans-serif;
  font-size: $font-md;
  font-weight: $font-weight-regular;
  line-height: 25px;
}

ul li {
  list-style: none;
}

.search-button {
  background-color: #7c3aed;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-button .icon {
  color: white;
}

.navbar-f {
  display: block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.responsive-mainlogo {
  max-width: 200px;
  min-height: 50px;
}

.search-canvas {
  background-color: whitesmoke;

  min-height: 40px;
  border-radius: 10px;
}

.mr-10 {
  position: relative;
  right: 200px;
}

.can-list {
  max-width: 400px;
  min-height: 40px;
  border-radius: 10px;
  border: 1px solid #B5B5C3;
  align-items: center;
  position: relative;
  right: 25px;
  padding: 8px 15px;
}

.can-icons {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #B5B5C3;
}

.top-center {
  margin-top: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.flex-1 {
  flex: 1;
}

.sub-menu {
  list-style: none;
  padding: 0;
  width: 320px;
  position: relative;
  right: 24px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: max-height 0.2s ease;
  display: block;

}

.sub-menu li {
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sub-menu li:hover {
  background-color: #e1e1e1;

}

.responsive-navbar {
  display: none;
}

// @media screen and (max-width: 767.98px) {
//   .navbar-f {
//     display: none;
//   }

//   .navbars-icons {
//     flex-direction: column;
//     text-align: center;
//   }

//   .navbar-options {
//     flex-direction: column;
//     gap: 20px;
//   }

//   .navbar-nav {
//     align-items: flex-start;
//   }

//   .btn-yellow {
//     width: unset;
//   }

//   .responsive-navbar {
//     display: block;
//   }
// }

// @media screen and (max-width: 1024px) {
//   .navbar-f {
//     display: none;
//   }

//   .navbars-icons {
//     flex-direction: column;
//     text-align: center;
//   }

//   .navbar-options {
//     flex-direction: column;
//     gap: 20px;
//   }

//   .navbar-nav {
//     align-items: flex-start;
//   }

//   .btn-yellow {
//     width: unset;
//   }

//   .responsive-navbar {
//     display: block;
//   }
// }

// @media screen and (max-width:1440px) {
//   .l-btn {
//     width: 70px;
//   }

// }
@media screen and (max-width: 767.98px),
screen and (max-width: 1024px) {
  .navbar-f {
    display: none;
  }

  .navbars-icons,
  .navbar-options {
    flex-direction: column;
    text-align: center;
  }

  .navbar-options {
    gap: 20px;
  }

  .navbar-nav {
    align-items: flex-start;
  }

  .btn-yellow {
    width: unset;
  }

  .responsive-navbar {
    display: block;
  }

  .res-mt {
    margin-top: 6px;
  }
}

@media screen and (max-width: 1440px) {
  .l-btn {
    width: 70px;
  }
}

@media screen and (max-width: 322px) {
  .icon-d {
    margin-right: 2px;
  }

  .navbars-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mr {
    margin-right: 16px;
  }

  .crs-env {
    margin-left: unset;
  }
}

@media screen and (max-width: 376px) {
  .icon-d {
    margin-right: -2px !important;
  }

}

@media screen and (max-width: 426px) {
  .icon-d {
    max-width: 291px !important;
  }

}