.subscription-breadcrum {
  background-image: url("../images/breadcrum.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 480px;
  color: #ffffff;
  font-size: 21px;
  line-height: 40px;
  font-family: "Inter", sans-serif;
}
.top-content {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.subscription-card {
  max-width: 1320px;
  min-height: 154px;
  box-shadow: 0px 0px 11px 2px #00000029;
  display: flex;
  justify-content: space-between;
  padding: 24px 48px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.sub-img {
  width: 106px;
  height: 106px;
}
.sub-text {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 28px;
}
.fw-400 {
  font-weight: 400;
}
.fw-700 {
  font-weight: 700;
}
.col-16 {
  color: #161439;
}
.col-6d {
  color: #6d6c80;
}
.sub-active {
  width: 139px;
  height: 49px;
  border-radius: 30px;
  background-color: #3ca550;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.92px;
  border: none;
}
.mt-29 {
  margin-top: 29px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-17 {
  margin-top: 17px;
}
.border-btm {
  border-bottom: 1px dashed #5751e1;
}
.mt-56 {
  margin-top: 56px;
}
.text-red {
  color: #fc4242;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
}
.sub-renew {
  width: 175px;
  height: 49px;
  border-radius: 30px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.92px;
  border: none;
  background-color: #5751e1;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-100 {
  margin-bottom: 100px;
}
.sub-cross {
  color: #fc4242;
  position: absolute;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
.seperator {
  border-right: 2px solid #7f7e97;
  height: 73px;
  position: relative;
}
.sub-content {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 30px;
}
.seperator.padding-1 {
  padding: 0px 85px;
}
.padding-2 {
  padding: 0px 187px;
}
.gap-30 {
  gap: 30px;
}
.sub-btn {
  display: flex;
  justify-content: center;
  gap: 20px;
}
