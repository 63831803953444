section.profile-breadcrum {
  background-image: url("../images/breadcrum.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 480px;
  color: #ffffff;
  font-size: 21px;
  line-height: 40px;
  font-family: "Inter", sans-serif;
}
.profile-section {
  padding-top: 60px;
  padding-bottom: 100px;
}
.mt-48 {
  margin-top: 48px;
}
.btn-profile {
  width: 202px;
  min-height: 49px;
  background-color: #ffc224;
  border-radius: 30px;
  border: none;
  padding: 13px 35px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.92px;
  font-family: "Inter", sans-serif;
  text-align: center;
  color: #161439;
}
.btn-subscription {
  width: 202px;
  min-height: 49px;
  background-color: #ebebeb;
  color: #161439;
  border-radius: 30px;
  border: none;
  padding: 13px 43px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.92px;
  font-family: "Inter", sans-serif;
  text-align: center;
}
.profile-card {
  width: 440px;
  height: 512px;
  border: 1px solid #7f7e97;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-img {
  width: 168px;
  height: 168px;
}
.btn-request {
  width: 222px;
  height: 49px;
  border-radius: 30px;
  border: none;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.92px;
  padding: 13px 24px;
  background-color: #5751e1;
}
.pt-60 {
  padding-top: 60px;
}
.grade-select {
  width: 392px;
  height: 50px;
  border: 1px solid #b5aaaa;
  color: #161439;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  border-radius: 5px;
  margin-top: 18px;
}
.profile-p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #6d6c80;
  margin-top: 5px;
}
.profile-h2 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #161439;
  margin-top: 16px;
}
.grid-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  max-width: 900px;
  margin: 0 auto;
}
.form-row {
  display: contents;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  text-align: flex-end;
  margin-top: 40px;
}

.btn-save {
  width: 222px;
  height: 49px;
  border-radius: 30px;
  border: none;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.92px;
  padding: 13px 24px;
  background-color: #b0b0b0;
  display: inline-block;
}
.round-circle {
  color: red;
  position: relative;
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;

  top: 26px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
