section.check-breadcrum {
  background-image: url("../images/breadcrum.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 480px;
  color: #ffffff;
  font-size: 21px;
  line-height: 40px;
  font-family: "Inter", sans-serif;
}
.form-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 20px;
  border: 1px solid #7f7e97;
  margin-bottom: 30px;
  margin-top: 58px;
  font-family: "Inter", sans-serif;
}

.check-h2 {
  margin-bottom: 24px;
  font-size: 24px;
  color: #333;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.form-group {
  flex: 1;
  margin-bottom: 16px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #555;
}

.check-input,
.check-select,
.check-textarea {
  width: 100%;
  padding: 8px 12px;
  border-bottom: 0.5px solid #b5aaaa;
  border-radius: 4px;
  font-size: 16px;
}

.check-input:focus,
.check-select:focus,
.check-textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.check-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M8 10l4-4H4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 36px;
}

.check-textarea {
  resize: vertical;
  height: 50px;
  border: none;
  border-bottom: 0.5px solid #b5aaaa;
}
.check-btn {
  width: 222px;
  height: 49px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.92px;
  text-align: center;
  color: #ffff;
  border-radius: 20px;
  background-color: #5751e1;
  border: none;
  padding: 13px 24px;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }
  section.check-breadcrum {
    font-size: 14px;
    line-height: 28px;
  }
  section.check-breadcrum h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48.41px;
  }
  section.check-breadcrum .breadcrumb {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}
