@import '../Styles/FontSize.scss';
@import '../Styles/Color.scss';

.footer-first {
    background-image: linear-gradient(#161439, #161439);
    min-height: 480px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 98px 64px;
}

.h2-touch {
    font-size: 22px;
    font-weight: 600;
    line-height: 28.6px;
    color: #ffffff;
    font-family: "Inter", sans-serif;
}

.li-footer {
    color: #B2BBCC;
    font-size: $font-md;
    font-weight: 500;
    line-height: 28px;
    font-family: "Inter", sans-serif;
}

.footer-strip {
    background-image: linear-gradient(#1C1A4A, #1C1A4A);
    min-height: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 75px;
}

.footer-logo {
    max-width: 250px;
    min-height: 62px;
}

.hr-tag {
    max-width: 30px;
    min-height: 4px;
    border-radius: 50px;
    background-color: #5751E1;
    border: none;
}

.mt-47 {
    margin-top: 47px;
}

.mt-16 {
    margin-top: 16px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-24 {
    margin-top: 24px;
}

.ml-150 {
    margin-left: 140px;
}

.ml-90 {
    margin-left: 90px;
}

.ml-60 {
    margin-left: 60px;
}

.ml-100 {
    margin-left: 105px;
}

.footer-ic {
    color: #B2BBCC;
    margin-right: 8px;
}

@media screen and (max-width: 767.98px) {
    .footer-logo {
        margin-left: 0px;
    }

    .ml-150,
    .ml-90,
    .man-sucess,
    .hr-tag,
    .footer-p,
    .footer-icons-div {
        margin-left: 30px;
    }

    .ml-100 {
        margin-left: unset;
    }

    .ml-60 {
        margin-left: unset;
    }

    .f-man {
        top: unset;
    }

    .btn-white {
        margin-left: 114px;
        position: relative;
        bottom: 20px;
    }

    h1 {
        text-align: center;
        font-size: medium;
    }

    .h2-touch {
        margin-left: 30px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 426px) {
    .footer-strip {
        padding: 30px 9px;
    }

}